import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Introduction from "../../components/introduction"
import HeroAlt from "../../components/hero-alt"
import Spacer from "../../components/spacer"
import TitleAndText from "../../components/title-and-text"
import ImageAndText from "../../components/image-and-text"
import BulletList from "../../components/bullet-list"
import Bullet from "../../components/bullet"
import CenteredSlider from "../../components/centered-slider"
import CenteredSlide from "../../components/centered-slide"
import WorkGrid from "../../components/work-grid"
import ImageGridItemService from "../../components/image-grid-item-service"
import ContactForm from "../../components/contact-form"
import ImageGridItem from "../../components/image-grid-item"


class WordpressSecurityPage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true } >
        <SEO title="WordPress Security Bournemouth" />
        <HeroAlt 
          image="/security.jpg"
          title="WordPress Security"
          subtitle="Old Salt is your trusted partner to help you to ensure that your WordPress website and your customer's data is kept safe and secure."
        />
        
        <Spacer space="4" />    

        <div className="row shrink-width align-center">

          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>Security</h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>Website security is vital to ensuring that your WordPress website is keeping both your and your customer's data protected from hackers and malicious activity. WordPress websites can become vulnerable to attacks when they’re not <Link class="underline-light" to="/services/wordpress-maintenance">updated</Link> and have the necessary security measures in place. </p>
                <p>We can help you ensure that your WordPress website is encrypted and add in security measures to make sure that this is something you never need to worry about again.</p>
                <p>As always, an important part of website security is to keep a well maintained website. It’s vitally important to make sure that your website is up-to-date, regularly scanned for malicious code and reviewed for 3rd party plugins that may seek to expose your website’s data.</p>
                <p>Don’t be caught out by neglecting your WordPress website’s security. Get in touch today to discover how we can help secure your website and keep you safe.</p>
              </div>              
            </div>
          </div>
        </div>


        <div className="gm-top--2 gm-bottom--2 contact-form--pullout">
          <div className="clearfix"></div>
          <div className="row align-center gm-top--4 gm-bottom--4">
            <div className="column small-12 medium-10 large-8">
              <h3>Get secure</h3>
              <p>Get in touch to find out how we can ensure that your WordPress website is up-to-date and secure.</p>
              <Spacer space="2" />
              <ContactForm pageName="wordpress-security" />
            </div>
          </div>
          <div className="clearfix"></div>
        </div>

        <WorkGrid>
          <ImageGridItemService width={6} image={ '/maintenance.jpg' } subtitle="Development" title="WordPress Maintenance" link="/services/wordpress-maintenance" />
          <ImageGridItemService width={6} image={ '/security.jpg' } subtitle="Development" title="WordPress Security" link="/services/wordpress-security" />
        </WorkGrid>
 
        
        <Spacer space="4" />   
        <CenteredSlider>
          {data.allWordpressPost.edges.map(({ node }) => (
              <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </CenteredSlider> 
      </Layout>
    )
  }
}

export default WordpressSecurityPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`